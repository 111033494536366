@import url("https://fonts.googleapis.com/css?family=Lora|Roboto+Mono:300,400,700");

html,
body {
  width: 100%;
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, "Roboto Mono", BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Roboto Mono", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}
