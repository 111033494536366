:root {
  --primaryDark: #101011;
  --primaryBright: #dddbcb;
  --accent: #5dfdcb;
  --secondary: #fca311;
  --lightText: #f4f3ed;
  --darkText: #373737;
}
.primaryDark {
  background: var(--primaryDark);
}
.primaryBright {
  background: var(--primaryBright);
}
.accent {
  background: var(--accent);
}
.secondary {
  background: var(--secondary);
}
.lightText {
  color: var(--lightText);
}
.darkText {
  color: var(--darkText);
}
.brightLink {
  color: var(--secondary);
  text-decoration: underline;
  font-weight: 800;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  font-family: -apple-system, "Roboto Mono", BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}
.darkLink {
  color: var(--darkText);
  text-decoration: underline;
  font-weight: 800;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  font-family: -apple-system, "Roboto Mono", BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}
.bold {
  font-weight: 800;
}
.serif {
  font-family: "Lora", serif;
}

.safe {
  width: 95%;
  height: 100%;
  max-width: 1440px;
  padding: 10px;
  /* border: 1px solid #1ebcf3; */
}

.App {
  text-align: center;
  background-color: var(--primaryDark);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 2vmin);
  color: white;
  box-sizing: border-box;
}

@media (max-width: 1024px) {
  .App {
    overflow: hidden;
  }
}
