nav {
  display: inline-block;
}
.navigationList {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-weight: 100;
  display: flex;
}
.navigationList li {
  padding: 5px;
  margin: 0 10px;
}
.navigationList li a {
  font-size: 18px;
}
/* 
@media (max-width: 1024px) {
  .navigationList {
    flex-direction: column;
  }
} */
