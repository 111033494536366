@import url(https://fonts.googleapis.com/css?family=Lora|Roboto+Mono:300,400,700);
html,
body {
  width: 100%;
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, "Roboto Mono", BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Roboto Mono", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

:root {
  --primaryDark: #101011;
  --primaryBright: #dddbcb;
  --accent: #5dfdcb;
  --secondary: #fca311;
  --lightText: #f4f3ed;
  --darkText: #373737;
}
.primaryDark {
  background: #101011;
  background: var(--primaryDark);
}
.primaryBright {
  background: #dddbcb;
  background: var(--primaryBright);
}
.accent {
  background: #5dfdcb;
  background: var(--accent);
}
.secondary {
  background: #fca311;
  background: var(--secondary);
}
.lightText {
  color: #f4f3ed;
  color: var(--lightText);
}
.darkText {
  color: #373737;
  color: var(--darkText);
}
.brightLink {
  color: #fca311;
  color: var(--secondary);
  text-decoration: underline;
  font-weight: 800;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  font-family: -apple-system, "Roboto Mono", BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}
.darkLink {
  color: #373737;
  color: var(--darkText);
  text-decoration: underline;
  font-weight: 800;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  font-family: -apple-system, "Roboto Mono", BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
}
.bold {
  font-weight: 800;
}
.serif {
  font-family: "Lora", serif;
}

.safe {
  width: 95%;
  height: 100%;
  max-width: 1440px;
  padding: 10px;
  /* border: 1px solid #1ebcf3; */
}

.App {
  text-align: center;
  background-color: #101011;
  background-color: var(--primaryDark);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 2vmin);
  color: white;
  box-sizing: border-box;
}

@media (max-width: 1024px) {
  .App {
    overflow: hidden;
  }
}

.homeContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.columns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}
.navigationContainer {
  padding: 0 0 20px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.mainTitleSection {
  box-sizing: border-box;
  text-align: left;
}
.mainTitleSection h2 {
  font-size: 36px;
  font-weight: 100;
  padding: 20px;
}
.mainTitleSection h1 {
  font-size: 144px;
  line-height: 130px;
  font-weight: 400;
  font-family: "Lora", serif;
  margin: 0;
  padding: 0 20px 40px;
}
.mainTitleSection h1 span {
  display: block;
}

h2.borderBottom {
  border-bottom: 1px solid var(--accent);
}
h2.borderTop {
  border-top: 1px solid var(--accent);
}

.abstractContainer {
  border: 2px solid #393939;
  box-sizing: border-box;
  box-shadow: 3px 4px 25px rgba(0, 0, 0, 0.42);
  border-radius: 5px;
  padding: 20px;
  width: 50%;
  max-width: 600px;
  color: #21252b;
  text-align: left;
  position: relative;
  font-size: 18px;
  height: 100%;
  font-weight: 400;
  margin-left: 10px;
}
.abstractContainer .darkLink {
  float: right;
}
.abstractList {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-weight: 100;
}
.abstractList li {
  margin: 15px 0;
}
.closingMessage {
  position: absolute;
  bottom: -80px;
  font-weight: 100;
  width: 350px;
  text-align: right;
  right: 0;
  margin-right: 20px;
}

@media (max-width: 1024px) {
  .homeContainer {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .columns {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .navigationContainer {
    padding: 0 0 20px;
    position: relative;
    text-align: right;
    margin-top: 40px;
  }
  .navigationContainer .navigationList {
    flex-wrap: wrap;
    justify-content: center;
  }
  .navigationContainer .navigationList li {
    margin: 10px;
  }
  .abstractContainer {
    width: 100%;
    font-size: 16px;
    margin-left: 0px;
    margin-bottom: 40px;
    margin-top: 10px;
  }
  .mainTitleSection {
    width: 100%;
  }
  .mainTitleSection h1 {
    font-size: 80px;
    line-height: 71px;
    padding: 0 5px 22px;
  }
  .mainTitleSection h2 {
    font-size: 18px;
    padding: 10px;
  }
  .closingMessage {
    width: 310px;
    margin-right: 8px;
    bottom: -70px;
  }
}

nav {
  display: inline-block;
}
.navigationList {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-weight: 100;
  display: flex;
}
.navigationList li {
  padding: 5px;
  margin: 0 10px;
}
.navigationList li a {
  font-size: 18px;
}
/* 
@media (max-width: 1024px) {
  .navigationList {
    flex-direction: column;
  }
} */

